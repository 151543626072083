<template>
  <div>
    <div class="notification-container">
      <div class="header">
        <h4>Notifications</h4>
        <div class="message">
          <div v-if="loading" class="loading">
            Chargement en cours...
          </div>
          <div v-if="getNotificationsError" class="error">
            <ul v-if="Array.isArray(getNotificationsError)">
              <li v-for="(e, index) in getNotificationsError" :key="index">
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ getNotificationsError }}</span>
          </div>
        </div>
        <b-button
          size="sm"
          class="button-actualiser"
          variant="outline-primary"
          @click="refresh"
          title="Actualiser"
        >
          <font-awesome-icon class="icon" icon="sync-alt" />
        </b-button>
      </div>
      <div class="notifications">
        <div
          class="labelEmpty"
          v-if="!getNotification || !getNotification.length"
        >
          Aucune Notification
        </div>

        <div
          @click="openActivity(notif, i)"
          v-for="(notif, i) in getNotification"
          :key="i"
        >
          <div class="notif" :class="{ 'gris-notif': notif.depuis == null }">
            <div class="contenu">
              <div class="ml-1 icon mb-1">
                <font-awesome-icon
                  v-if="notif.icon == 'note' || notif.activity_type == 'note'"
                  class="icon-entity-note-comment"
                  icon="comment-alt"
                />
                <font-awesome-icon
                  v-else
                  class="icon-historique-activity-autre"
                  icon="hourglass-start"
                />
              </div>
              <div class="text">
                <div class="ml-3 titre">
                  {{ notif.activity_name }}
                </div>
                <div class="ml-3 content">
                  {{ notif.contenu.split(' , ')[0] }}
                </div>
              </div>
            </div>
            <div>
              <div
                class="pointe-notif"
                v-if="notif.depuis == null || notif.seen == 0"
              ></div>
              <div class="dropdown">
                <p class="time" v-if="notif.depuis != null">
                  {{ notif.activity_date_start | timeFromNow(notif) }}
                </p>
              </div>
            </div>
          </div>
          <hr class="style-hr" v-if="getNotification.length - 1 != i" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
moment.locale('fr')

export default {
  name: 'Notifications',
  data() {
    return {
      popup: false,
      loading: false
    }
  },
  computed: {
    ...mapGetters([
      'getNotification',
      'currentUser',
      'getNotificationsProcessing',
      'getNotificationsError'
    ])
  },
  methods: {
    ...mapActions(['fetchListNotification', 'fetchListNotificationNotVu']),
    async openActivity(notif) {
      const payload = {
        activity_id: notif.activity_id,
        activity_type: notif.icon
          ? notif.icon
          : notif.activity_type == 'Autre'
          ? 'activity'
          : notif.activity_type
      }
      const response = await this.fetchListNotificationNotVu(payload)
      if (response) {
        this.fetchListNotification(this.currentUser.id)
      }
      // this.$router.push()
      this.$router
        .replace('/projects/' + notif.projet_id + '/activity')
        .catch(() => {})
    },
    refresh() {
      this.loading = true
      this.fetchListNotification(this.currentUser.id)
      setTimeout(() => {
        this.loading = false
      }, 1000)
    }
  },
  filters: {
    timeFromNow: function(value, notif) {
      if (!value) return ''
      var seconds = notif.time.split(':')[2]
      var minutes = notif.time.split(':')[1]
      var hours = notif.time.split(':')[0]
      var result = ''
      var starttime = moment(value, 'YYYY-MM-DD hh:mm:ss')
      var diff = ''
      if (notif.depuis == '1') {
        result = moment(value)
          .add(seconds, 'seconds')
          .add(minutes, 'minutes')
          .add(hours, 'hours')
        diff = moment
          .duration(starttime.diff(result))
          .locale('fr')
          .humanize(true)
      } else {
        result = moment(value)
          .subtract(seconds, 'seconds')
          .subtract(hours, 'hours')
          .subtract(minutes, 'minutes')
        diff = moment
          .duration(starttime.diff(result))
          .locale('fr')
          .humanize(true)
      }

      return diff
    }
  },
  mounted() {
    this.fetchListNotification(this.currentUser.id)
  }
}
</script>

<style lang="scss" scoped>
.notification-container {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 90%;
  margin-top: 16px;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 5px;
  // box-shadow: 0px 0px 40px 4px rgba(0, 0, 0, 0.16);

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 12px 16px 8px;
    padding: 14px 0;
    border-bottom: 1px solid hsla(0, 0%, 0%, 0.25);
    .button-actualiser {
      cursor: pointer;
      padding: 8px 10px;
      font-size: 0.875rem;
      line-height: 1.5;
      border-radius: 0.2rem;
      color: var(--primary);
      background-color: var(--white);
      border-color: var(--primary);
      border: 1px solid var(--primary);
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      align-items: center;
      &:hover {
        color: var(--primary);
        background-color: var(--white);
        border-color: var(--primary);
        border: 1px solid var(--primary);
      }
    }
    & h1 {
      font-size: 24px;
      font-weight: 600;
    }

    & button {
      padding: 0.25rem 0.5rem;
      font-size: 0.875rem;
      line-height: 1.5;
      border-radius: 0.2rem;
      color: #fff;
      background-color: #28a745;
      border-color: #28a745;
      border: 1px solid transparent;
      display: inline-flex;
      align-items: center;

      &:hover {
        background-color: #218838;
        border-color: #1e7e34;
      }
    }
  }

  .notifications {
    // max-height: 500px;
    // overflow: auto;
    .label,
    .labelEmpty {
      padding: 20px 16px 4px;
      color: #000;
      font-weight: 600;
      font-size: 1.0625rem;
      line-height: 1.1765;
    }
    .labelEmpty {
      color: rgba(0, 0, 0, 0.6);
      text-align: center;
      margin-bottom: 16px;
    }
    .notif {
      color: #212529;
      text-decoration: none;
      margin: 8px 4px;
      padding: 8px 12px;
      cursor: pointer;
      border-radius: 5px;
      transition: all 0.3s ease;
      text-align: right;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &.gris-notif {
        background-color: #2dabe214;
      }
      &:hover {
        background-color: rgb(45 171 226 / 28%);
      }
      .contenu {
        display: flex;
        align-items: center;
        .icon {
          border: 1px solid;
          padding: 6px 10px;
          border-radius: 20px;
          font-size: 14px;
          color: #6c757d;
        }
        .text {
          text-align: left;

          .titre {
            text-transform: capitalize;
            font-weight: bold;
          }
          .content {
            font-size: 14px;
          }
        }
      }
      .pointe-notif {
        background: #2dabe2;
        border-radius: 50%;
        // position: absolute;
        // right: 225px;
        // top: 118px;
        display: inline-block;
        width: 10px;
        height: 10px;
      }
      .dropdown {
        display: flex;
        flex-direction: column;
        align-items: center;
        .time {
          color: #007bff;
          font-size: 12px;
          margin: 0;
        }

        button {
          height: 2rem;
          width: 2rem;
          font-weight: 600;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          border: none;

          .icon {
            display: inline-block;
            font-size: 16px;
          }

          &:active {
            background: rgba(#000, 0.1);
          }
        }
      }
    }
    .style-hr {
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
}
@media screen and (max-width: 827px) {
  .notification-container {
    margin-top: 2px;
    width: 100%;
    overflow: auto;
  }
}
</style>
